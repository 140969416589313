.LegacyContainer {
  max-width: 81rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1261px) {
  .LegacyContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
